import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE_TITLE_BUTTON_TYPE } from "../../constants/Enums";
import PageTitle from "../../components/PageTitle";
import PageTransitionWrapper from "../../components/PageTransitionWrapper";
import Button from "../../components/common/Button";
import {
  protectUserEmail,
  saveProtectedEmailReference,
  getSavedEmail,
} from "../../services/web3mail";
import useWallet from "../../lib/store/hooks/useWallet";
import LoaderComponent from "../../components/common/LoaderComponent";
import { getUserSettingsData } from "../../utils/helper";

const EmailNotification = () => {
  const [email, setEmail] = useState("");
  const [hasAccess, setHasAccess] = useState(false);
  const [willReceiveEmails, setWillReceiveEmails] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [hasSavedEmail, setHasSavedEmail] = useState(false);
  const [savedEmailAddress, setSavedEmailAddress] = useState("");
  const { eoaAddress, eoaPrivateKey } = useWallet();
  const navigate = useNavigate();

  // Truncate wallet address for display
  const displayAddress = eoaAddress
    ? `${eoaAddress.substring(0, 6)}...${eoaAddress.substring(
        eoaAddress.length - 6,
      )}`
    : "";

  // Helper function to check if user has a protected email
  const hasSavedProtectedEmail = async (address: string) => {
    try {
      // Get current user settings
      const userSettings = await getUserSettingsData();

      // Find the user's settings
      const userIndex = userSettings.findIndex(
        (user) => user.address === address,
      );

      return (
        userIndex !== -1 &&
        userSettings[userIndex]?.emailNotification &&
        userSettings[userIndex]?.emailNotification?.protectedDataAddress
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error checking protected email:", err);
      return false;
    }
  };

  // Load saved email when component mounts
  useEffect(() => {
    const loadSavedEmail = async () => {
      if (!eoaAddress) return;

      try {
        setIsLoadingEmail(true);
        // Check if user has a saved email
        const protectedData = await getSavedEmail(eoaAddress);

        if (protectedData) {
          setHasSavedEmail(true);
          setSavedEmailAddress(protectedData);
        } else {
          // Check settings directly as fallback
          const hasEmailSaved = await hasSavedProtectedEmail(eoaAddress);

          if (hasEmailSaved) {
            setHasSavedEmail(true);
            setSavedEmailAddress("Protected Email Address");
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Failed to load saved email:", err);
      } finally {
        setIsLoadingEmail(false);
      }
    };

    loadSavedEmail();
  }, [eoaAddress]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError("");
    setIsSuccess(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email || !hasAccess || !eoaAddress || !eoaPrivateKey) {
      setError("Missing required information. Please try again.");
      return;
    }

    try {
      setIsLoading(true);
      setError("");
      setIsSuccess(false);

      // Protect the email using Web3Mail
      const protectedData = await protectUserEmail(
        email,
        eoaAddress,
        eoaPrivateKey,
      );

      // Save the protected data reference to user settings
      await saveProtectedEmailReference(
        eoaAddress,
        protectedData.protectedDataAddress,
        protectedData.email,
      );

      const verifiedEmail = await getSavedEmail(eoaAddress);

      if (verifiedEmail) {
        setIsSuccess(true);
        setHasSavedEmail(true);
        setSavedEmailAddress(email);
      } else {
        throw new Error("Email was not saved properly");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Failed to protect email:", err);
      setError(
        `Failed to protect email: ${
          err instanceof Error ? err.message : String(err)
        }`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <PageTransitionWrapper>
      <div className="flex flex-col h-full w-full bg-black text-white">
        <PageTitle
          title="Email Notifications"
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
          rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
          leftDestinationRoute="/settings"
        />

        <div className="flex flex-col p-4 h-[calc(100%-60px)] justify-between">
          <div className="flex-1">
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoadingEmail ? (
              <div className="flex flex-col items-center justify-center py-8">
                <LoaderComponent />
                <p className="mt-4 text-center">Loading saved email...</p>
              </div>
            ) : // eslint-disable-next-line no-nested-ternary
            isLoading ? (
              <div className="flex flex-col items-center justify-center py-8">
                <LoaderComponent />
                <p className="mt-4 text-center">Protecting your email...</p>
              </div>
            ) : // eslint-disable-next-line no-nested-ternary
            isSuccess ? (
              <div className="flex flex-col items-center justify-center py-6">
                <div className="text-green-500 mb-4 text-center font-medium">
                  Email protected successfully!
                </div>
                <p className="text-sm text-center mb-6">
                  Your email has been protected using Web3Mail. You'll only
                  receive communications from services you explicitly authorize.
                </p>
              </div>
            ) : hasSavedEmail ? (
              <div className="flex flex-col">
                <h3 className="text-lg font-medium mb-2">
                  Your Protected Email
                </h3>
                <div className="bg-gray-800 p-4 rounded-lg mb-6">
                  <p className="text-sm font-medium">
                    Protected Email Address:
                  </p>
                  <p className="text-gray-300 mb-2 break-all">
                    {savedEmailAddress}
                  </p>
                  <p className="text-xs text-gray-400 mt-2">
                    Your email is protected using Web3Mail encryption. Only
                    services you authorize can contact you.
                  </p>
                </div>
                <div className="mb-4">
                  <h3 className="text-md font-medium mb-3">
                    Associated Wallet Address
                  </h3>
                  <div className="bg-gray-700 text-center py-3 px-4 rounded-md">
                    {displayAddress}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="mb-4">
                  <h3 className="text-md font-medium mb-3 mt-3">
                    Add Email Address
                  </h3>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="your@email.com"
                    className="w-full bg-white text-black px-4 py-3 rounded-md border-1"
                  />
                </div>

                <div className="flex items-center space-x-2 mb-6">
                  <input
                    id="hasAccess"
                    type="checkbox"
                    checked={hasAccess}
                    onChange={(e) => setHasAccess(e.target.checked)}
                    className="h-5 w-5 rounded-sm"
                  />
                  <label htmlFor="hasAccess" className="text-gray-300">
                    I have access to this email address
                  </label>
                </div>

                <div className="mb-4">
                  <h3 className="text-md font-medium mb-3">
                    Associated Wallet Address
                  </h3>
                  <div className="bg-gray-700 text-center py-3 px-4 rounded-md">
                    {displayAddress}
                  </div>
                </div>

                <div className="flex items-center space-x-2 mb-6">
                  <input
                    id="willReceiveEmails"
                    type="checkbox"
                    checked={willReceiveEmails}
                    onChange={(e) => setWillReceiveEmails(e.target.checked)}
                    className="h-5 w-5 rounded-sm"
                  />
                  <label
                    htmlFor="willReceiveEmails"
                    className="text-gray-300 text-md"
                  >
                    I will receive an email when someone sends a message to this
                    address from WalletX
                  </label>
                </div>

                {error && (
                  <div className="text-red-500 text-sm mb-4">{error}</div>
                )}
              </>
            )}
          </div>

          {/* Footer section */}
          <div className="mt-4 pt-4 border-t border-gray-800">
            <div className="text-center mb-4">
              <a
                href="https://iex.ec"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white text-sm"
              >
                Powered by iEXEC
              </a>
            </div>

            {!isLoadingEmail && !isLoading && (
              <Button
                onClick={
                  isSuccess || hasSavedEmail ? handleGoBack : handleSubmit
                }
                disabled={
                  !isSuccess &&
                  !hasSavedEmail &&
                  (!email || !hasAccess || isLoading)
                }
                className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 rounded-md"
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {isSuccess || hasSavedEmail
                  ? "Go to Homepage"
                  : isLoading
                  ? "Connecting..."
                  : "Connect Email Address"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </PageTransitionWrapper>
  );
};

export default EmailNotification;
